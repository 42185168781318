import { useContext, useState } from "react";
import { AppContext } from "../App";
import api from "./api/api";
import logo from "../images/logo.png";
import GearIcon from "../icons/GearIcon";
import LogoutIcon from "../icons/LogoutIcon";
import HamburgerIcon from "../icons/HamburgerIcon";
import DrawerIcon from "../icons/DrawerIcon";
import DotIcon from "../icons/DotIcon";
import HeaderRight from "./HeaderRight";

export default function Footer() {
	const {
		apiSettings,
		config,
		setSettingsVisible,
		setUser,
		setConfirm,
		setSellsListVisible,
		cloudStatus,
		setReportsPopUpVisible,
		shop,
		setOverlay,
	} = useContext(AppContext);
	const [menuOpen, setMenuOpen] = useState(false);

	function toggleSubmenu() {
		if (menuOpen) {
			setMenuOpen(false);
		} else {
			setMenuOpen(true);
			setOverlay({
				action: () => {
					setMenuOpen(false);
					setOverlay(false);
				},
			});
		}
	}

	const sendCommand = async (type = "DAILY_FINANCIAL_REPORT") => {
		console.log("sendCommand");

		setConfirm({
			title: "Sei sicuro?",
			onConfirm: async () => {
				const res = await api.postProtected("/fiscal_printers/send_command/", {
					fiscal_printer_id: config.fiscal_printer_id,
					command: type,
				});
				if (res.success == 0)
					setConfirm({
						title: "Errore",
						message: res.error,
					});
			},
		});
	};

	const sendThermalPrintersCommand = async (command) => {
		console.log("sendThermalPrintersCommand", command);

		setConfirm({
			title: "Sei sicuro?",
			onConfirm: async () => {
				const res = await api.postProtected("/thermal_printers/send_command/", {
					shop_id: shop.id,
					command: command,
				});
				if (res.success == 0)
					setConfirm({
						title: "Errore",
						message: res.error,
					});
			},
		});
	};

	return (
		<div className="footer">
			<div className="logo-container">
				<img src={logo} className="logo" />
				<div className="shop">{shop.name}</div>
			</div>
			<div className="group">
				<div className="buttons-container">
					<div className={"menu-container" + (menuOpen ? " open" : "")}>
						<button
							onClick={() => {
								setSellsListVisible(true);
								setMenuOpen(false);
								setOverlay(false);
							}}
						>
							Vendite
						</button>
						<button
							onClick={() => {
								setMenuOpen(false);
								setReportsPopUpVisible(true);
								setOverlay(false);
							}}
						>
							Reports contabili
						</button>
						{apiSettings.mandant.fiscal_printers == 1 && (
							<>
								<button
									onClick={() => {
										sendCommand("DUPLICATE");
										setMenuOpen(false);
										setOverlay(false);
									}}
								>
									Ristampa ultimo scontrino
								</button>
								<button
									onClick={() => {
										sendCommand("DAILY_FINANCIAL_REPORT");
										setMenuOpen(false);
									}}
								>
									Stampa report giornaliero
								</button>
								<button
									onClick={() => {
										sendCommand("DAILY_FISCAL_CLOUSE");
										setMenuOpen(false);
										setOverlay(false);
									}}
								>
									Stampa chiusura
								</button>
							</>
						)}
						<button
							onClick={() => {
								setMenuOpen(false);
								sendThermalPrintersCommand("check_unprinted");
								setOverlay(false);
							}}
						>
							Stampa ordini arretrati
						</button>
						<button
							onClick={() => {
								setConfirm({
									title: "Log Out?",
									onConfirm: () => {
										setUser(false);
										localStorage.removeItem("pos-user");
										setMenuOpen(false);
										setOverlay(false);
									},
								});
							}}
						>
							Log-Out
						</button>
					</div>
					{config.mode == "local" && (
						<button className="icon" style={{ color: cloudStatus ? "green" : "red" }}>
							<DotIcon />
							Cloud
						</button>
					)}
					<button onClick={toggleSubmenu}>
						<HamburgerIcon />
					</button>
					{apiSettings.mandant.fiscal_printers == 1 && (
						<button
							onClick={() => {
								sendCommand("OPEN_DRAWER");
								setMenuOpen(false);
							}}
						>
							<DrawerIcon />
						</button>
					)}
					<button
						onClick={() => {
							setSettingsVisible(true);
							setMenuOpen(false);
						}}
					>
						<GearIcon />
					</button>
				</div>
				{config.buttons_position == "bottom" && <HeaderRight />}
			</div>
		</div>
	);
}
