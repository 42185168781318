import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import ButtonLabeled from "./ButtonLabeled";
import DatePopUp from "./DatePopUp";
import List from "./List";
import moment from "moment/moment";
import CustomersPopUp from "./CustomersPopUp";
import Table from "../icons/Table";
import Takeaway from "../icons/Takeaway";
import Delivery from "../icons/Delivery";
import ListIcon from "../icons/ListIcon";
import UserIcon from "../icons/UserIcon";
import PinIcon from "../icons/PinIcon";
import CalendarIcon from "../icons/CalendarIcon";
import BarIcon from "../icons/BarIcon";
import ShopIcon from "../icons/ShopIcon";

function Header() {
	const {
		apiSettings,
		cart,
		setPopup,
		lists,
		editCart2,
		setFilteredCategories,
		menu,
		category,
		setCategory,
		shop,
		list,
		setList,
		setAddressPopUpVisible,
		editTable,
		setTablesListVisible,
		stock,
		colors,
		lockEdit,
		shops,
		setShop,
	} = useContext(AppContext);
	const [filteredLists, setFilteredLists] = useState([]);

	useEffect(() => {
		if (shop) filterLists();
	}, [shop]);

	useEffect(() => {
		if (list) localStorage.setItem("pos-list", JSON.stringify(list));
		else localStorage.removeItem("pos-list");
	}, [list]);

	useEffect(() => {
		if (shop && !list && apiSettings.pos.hide_base_list == 1) {
			setPopup({
				title: "Listini",
				content: (
					<List
						mode={3}
						elements={filteredLists}
						onClick={(l) => {
							setPopup(false);
							setList(l);
							editCart2({
								list_id: l.id,
							});
						}}
						cancelElement={apiSettings.pos.hide_base_list == 1 ? false : "Listino Base"}
					/>
				),
			});
		}
	}, [shop, list, filteredLists]);

	useEffect(() => {
		if (menu) filterMenu();
	}, [menu, list, shop, apiSettings, stock, colors, cart]);

	const filterLists = () => {
		//console.log("filterLists");
		const filteredArr = lists.filter((el) => {
			return (
				el.shops.filter((s) => {
					return s.id == shop.id;
				}).length > 0
			);
		});
		setFilteredLists(filteredArr);
	};

	function filterMenu() {
		console.log("filterMenu");
		//let res = structuredClone(menu)
		let res = [];
		for (const course of menu) {
			const cr = structuredClone(course);
			cr.categories = [];
			for (const category of course.categories) {
				if (!category.hasOwnProperty("active_pos") || category.active_pos == 1) {
					const ct = structuredClone(category);
					if (shop && list) {
						ct.products = [];
						for (const product of category.products) {
							let p = filterVariants(product);
							p.stock = getProductStock(p.id);
							p.color = getProductColor(p.id);
							const priceList = product.prices.filter((el) => {
								return el.list_id == list.id && el.price;
							});
							const assortment = product.assortment.filter((el) => {
								return (
									el.shop_id == shop.id && el.list_id == list.id && el.active == 1
								);
							});
							if (
								priceList.length > 0 &&
								assortment.length > 0 &&
								check_ingredients_assortment(product)
							) {
								p.price = priceList[0].price;
								ct.products.push(p);
							}
						}
						if (ct.products.length > 0) cr.categories.push(ct);
					} else if (shop) {
						ct.products = [];
						for (const product of category.products) {
							let p = filterVariants(product);
							p.stock = getProductStock(p.id);
							p.color = getProductColor(p.id);
							if (check_ingredients_assortment(product)) {
								ct.products.push(p);
							}
						}
						if (ct.products.length > 0) cr.categories.push(ct);
					} else {
						if (category.products.length > 0) cr.categories.push(category);
					}
				}
			}
			if (cr.categories.length > 0) res.push(cr);
		}
		setFilteredCategories(res);
		if (!category && res.length > 0 && res[0].categories.length > 0)
			setCategory(res[0].categories[0]);
		if (category) {
			const i = res.findIndex((vc) => {
				const cat = vc.categories.filter((c) => {
					return c.id == category.id;
				});
				return cat.length > 0;
			});

			if (i > -1) {
				const n = res[i].categories.findIndex((c) => c.id == category.id);
				if (n > -1) setCategory(res[i].categories[n]);
				else setCategory(res[0].categories[0]);
			} else if (res.length == 0) setCategory({ categories: [] });
			else setCategory(res[0].categories[0]);
		}
	}

	const filterVariants = (product) => {
		let p = JSON.parse(JSON.stringify(product));
		p.variants_categories = [];
		for (const variants_category of product.variants_categories) {
			let vc = JSON.parse(JSON.stringify(variants_category));
			vc.variants = [];
			vc.variants2 = [];
			vc.products = [];
			for (const variant of variants_category.variants) {
				let v = JSON.parse(JSON.stringify(variant));
				if (shop && list.id) {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == list.id && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == list.id && el.active == 1;
					});
					if (priceList.length > 0 && assortment.length > 0) {
						v.price = priceList[0].price;
						vc.variants.push(v);
					}
				} else {
					vc.variants.push(v);
				}
			}
			for (const variant of variants_category.variants2) {
				let v = JSON.parse(JSON.stringify(variant));
				if (shop && list.id) {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == list.id && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == list.id && el.active == 1;
					});
					if (priceList.length > 0 && assortment.length > 0) {
						v.price = priceList[0].price;
						vc.variants2.push(v);
					}
				} else {
					vc.variants2.push(v);
				}
			}
			for (const variant of variants_category.products) {
				let v = JSON.parse(JSON.stringify(variant));
				if (shop && list.id) {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == list.id && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == list.id && el.active == 1;
					});
					if (
						priceList.length > 0 &&
						(assortment.length > 0) & check_ingredients_assortment(variant)
					) {
						v.price = priceList[0].price;
						vc.products.push(v);
					}
				} else if (shop) {
					if (check_ingredients_assortment(variant)) {
						vc.products.push(v);
					}
				} else {
					vc.products.push(v);
				}
			}
			if (vc.variants.length > 0 || vc.variants2.length > 0 || vc.products.length > 0)
				p.variants_categories.push(vc);
		}
		return p;
	};

	const check_ingredients_assortment = (product) => {
		if (!apiSettings.menu_api) return true;
		if (!apiSettings.menu_api.enable_ingredients_assortment) return true;
		if (apiSettings.menu_api.enable_ingredients_assortment != 1) return true;
		if (!product.ingredients) return true;
		if (product.ingredients.length == 0) return true;
		for (const ingredient of product.ingredients) {
			const assortment = ingredient.assortment.filter((el) => {
				return el.shop_id == shop.id && el.active == 1;
			});
			if (assortment.length == 0) return false;
		}
		return true;
	};

	const getProductStock = (productId) => {
		if (stock || cart) {
			const s = stock.products.find((e) => e.shop_id == shop.id && e.product_id == productId);
			const i = stock.ingredients.filter(
				(e) => e.shop_id == shop.id && e.product_id == productId
			);
			return {
				product: s ? parseInt(s.qty) - getQtySum(productId) : false,
				ingredients: subCartQty(i),
			};
		}
		return { product: false, ingredients: [] };
	};

	const subCartQty = (i) => {
		const res = [];
		for (const ingredient of i) {
			res.push({
				...ingredient,
				qty: parseInt(ingredient.qty) - getIngredientQtySum(ingredient.ingredient_id),
			});
		}
		return res;
	};

	const getQtySum = (id) => {
		let sum = 0;
		for (const element of cart.products) {
			if (element.id == id) sum += element.qty;
		}
		return sum;
	};

	const getIngredientQtySum = (id) => {
		let sum = 0;
		for (const product of cart.products) {
			for (const ingredient of product.ingredients) {
				if (ingredient.id == id) sum += product.qty;
			}
		}
		return sum;
	};

	const getProductColor = (productId) => {
		const s = colors.find((e) => e.shop_id == shop.id && e.product_id == productId);
		if (s) return s.color;
		return false;
	};

	return (
		<div className="header">
			{cart && (
				<>
					<ButtonLabeled
						label="Negozio"
						icon={<ShopIcon />}
						vertical={true}
						value={shop ? shop.name : ""}
						onClick={() =>
							!lockEdit &&
							setPopup({
								title: "Negozi",
								content: (
									<List
										mode={3}
										elements={shops}
										onClick={(l) => {
											setPopup(false);
											setShop(l);
											editCart2({
												shop_id: l.id,
											});
										}}
									/>
								),
							})
						}
					/>
					<ButtonLabeled
						label="Listino"
						icon={<ListIcon />}
						vertical={true}
						value={list ? list.name : "Base"}
						onClick={() =>
							!lockEdit &&
							setPopup({
								title: "Listini",
								content: (
									<List
										mode={3}
										elements={filteredLists}
										onClick={(l) => {
											setPopup(false);
											setList(l);
											if (cart.table)
												editTable(cart.table.id, {
													list_id: l.id ? l.id : null,
												});
											editCart2({
												list_id: l.id,
											});
										}}
										cancelElement={
											apiSettings.pos.hide_base_list == 1
												? false
												: "Listino Base"
										}
									/>
								),
							})
						}
					/>
					<div className="separator" />
					<ButtonLabeled
						label="Banco"
						selected={cart.delivery_type.id == "shop" && !cart.table}
						icon={<BarIcon />}
						vertical={true}
						onClick={() => {
							if (!lockEdit) {
								editCart2({
									delivery_type: { id: "shop", name: "Sala" },
									delivery_date: null,
									address: false,
									delivery_time_range: null,
									table: null,
								});
							}
						}}
					/>
					<ButtonLabeled
						label="Tavolo"
						selected={cart.delivery_type.id == "shop" && cart.table}
						icon={<Table />}
						vertical={true}
						value={cart.table ? cart.table.name : ""}
						onClick={() => !lockEdit && setTablesListVisible(true)}
					/>
					<ButtonLabeled
						label="Asporto"
						selected={cart.delivery_type.id == "takeaway"}
						icon={<Takeaway />}
						vertical={true}
						onClick={() =>
							!lockEdit &&
							editCart2({
								delivery_type: { id: "takeaway", name: "Asporto" },
								delivery_date: null,
								address: false,
								delivery_time_range: null,
								table: null,
							})
						}
					/>
					<ButtonLabeled
						label="Domicilio"
						selected={cart.delivery_type.id == "delivery"}
						icon={<Delivery />}
						vertical={true}
						onClick={() =>
							!lockEdit &&
							editCart2({
								delivery_type: { id: "delivery", name: "Domicilio" },
								delivery_date: null,
								address: false,
								delivery_time_range: null,
								table: null,
							})
						}
					/>

					<div className="separator" />
					<ButtonLabeled
						label="Cliente"
						icon={<UserIcon />}
						vertical={true}
						valid={
							(apiSettings.pos.mandatory_customer == 0 &&
								cart.delivery_type.id != "delivery") ||
							cart.origin == "glovo" ||
							cart.origin == "deliveroo" ||
							cart.origin == "justeat" ||
							cart.customer
						}
						value={
							cart.customer
								? cart.customer.surname + " " + cart.customer.name
								: cart.name
						}
						onClick={() =>
							!lockEdit &&
							setPopup({
								title: "Cliente",
								content: <CustomersPopUp />,
							})
						}
					/>
					{cart.delivery_type.id == "delivery" && (
						<>
							<div className="separator" />
							<ButtonLabeled
								label="Indirizzo"
								icon={<PinIcon />}
								vertical={true}
								valid={cart.address}
								disabled={cart.delivery_type.id == "delivery" && !cart.customer}
								value={
									cart.address
										? cart.address.address +
										  " " +
										  cart.address.number +
										  "\n" +
										  cart.address.city +
										  " (" +
										  cart.address.province +
										  ")"
										: ""
								}
								onClick={() => !lockEdit && setAddressPopUpVisible(true)}
							/>
						</>
					)}

					{cart.delivery_type.id != "shop" && (
						<>
							<div className="separator" />
							<ButtonLabeled
								label="Data"
								icon={<CalendarIcon />}
								vertical={true}
								valid={cart.delivery_date}
								disabled={cart.delivery_type.id == "delivery" && !cart.address}
								value={
									cart.delivery_date
										? moment(cart.delivery_date, "YYYY-MM-DD HH:mm:ss").format(
												"DD/MM/YYYY HH:mm"
										  )
										: ""
								}
								onClick={() =>
									setPopup({
										title: "Data",
										content: <DatePopUp />,
									})
								}
							/>
						</>
					)}
				</>
			)}
		</div>
	);
}

export default Header;
