import { useContext, useEffect, useState } from "react";
import UserIcon from "../icons/UserIcon";
import { AppContext } from "../App";
import functions from "../functions/functions";
import moment from "moment/moment";
import "moment/locale/it";
import api from "./api/api";
import EditIcon from "../icons/EditIcon";
import AdvanceIcon from "../icons/AdvanceIcon";
import CheckIcon from "../icons/CheckIcon";
import WaiterIcon from "../icons/WaiterIcon";
import SplitIcon from "../icons/SplitIcon";
import PrinterIcon from "../icons/PrinterIcon";
import MoveIcon from "../icons/MoveIcon";
moment.locale("it");

function SidebarContent() {
	const {
		apiSettings,
		setConfirm,
		setLoading,
		init_cart,
		setTablesListVisible,
		orders,
		table,
		setOpenTablePopupVisible,
		setTablePopup,
	} = useContext(AppContext);
	const [tableOrders, setTableOrders] = useState([]);

	useEffect(() => {
		if (table) {
			loadTableOrders();
		}
	}, [table, orders]);

	const mergeOrders = async (id) => {
		console.log("mergeOrders");
		setLoading(true);
		const res = await api.postProtected("/tables/merge_orders/" + id + "/", {});
		setLoading(false);
		if (res.success == 0) alert(res.error);
	};

	const reOpen = (id) => {
		console.log("reOpen");
		const res = api.postProtected("/tables/edit/" + id + "/", { status: 1, disable_reset: 1 });
		init_cart();
	};

	const closeTable = (id) => {
		console.log("closeTable");
		const res = api.postProtected("/tables/edit/" + id + "/", { status: 0 });
		setTablePopup(false);
	};

	const fillAll = (id) => {
		console.log("fillAll");
		const res = api.postProtected("/tables/fill_orders/" + id + "/");
		if (res.success == 0) alert(res.error);
	};

	const loadTableOrders = async () => {
		console.log("loadTableOrders");
		if (apiSettings.orders.tables_open_by_filled == 1) {
			const filteredOrders = orders.filter((o) => o.table_id == table.id);
			setTableOrders(filteredOrders);
		} else {
			const filteredOrders = orders.filter(
				(o) => o.self_cart_id && o.self_cart_id == table.cart_id
			);
			setTableOrders(filteredOrders);
		}
	};

	const handleNewOrder = async () => {
		if (table.status == 0) {
			setOpenTablePopupVisible(true);
		} else {
			init_cart({
				list_id: table.list_id,
				table: table,
				room_id: table.room_id,
				delivery_type: {
					id: "shop",
					name: "Sala",
				},
				delivery_date: null,
				address: false,
				delivery_time_range: null,
				self_cart_id: table.cart_id,
			});
			setTablesListVisible(false);
			setTablePopup(false);
		}
	};

	return (
		<div className="sidebar">
			{table && (
				<>
					<div className="sidebar-header">
						<div>{table.name}</div>
						<div>{functions.formatter.format(table.orders_total)}</div>
					</div>

					<div className="sidebar-content">
						<SidebarOrders orders={tableOrders} />
					</div>
					<div className="sidebar-footer">
						{table.status != 2 && (
							<button className="button button-3" onClick={handleNewOrder}>
								Nuovo ordine
							</button>
						)}
						{tableOrders.length > 1 && table.status != 0 && (
							<button
								className="button button-3"
								onClick={() => {
									setConfirm({
										title: "Sei sicuro?",
										message: "",
										onConfirm: () => mergeOrders(table.id),
									});
								}}
							>
								Unisci ordini
							</button>
						)}
						{apiSettings.mandant.fiscal_printers == 1 && (
							<>
								{table.status == 2 && (
									<button
										className="button button-3"
										onClick={() => {
											setConfirm({
												title: "Sei sicuro?",
												message:
													"Procedo con la riapertura di " + table.name,
												onConfirm: () => reOpen(table.id),
											});
										}}
									>
										Annulla preconto
									</button>
								)}
							</>
						)}
						{apiSettings.orders.tables_open_by_filled != 1 &&
							apiSettings.mandant.fiscal_printers == 0 &&
							table.status != 0 && (
								<button
									className="button button-3"
									onClick={() => {
										setConfirm({
											title: "Sei sicuro?",
											onConfirm: () => closeTable(table.id),
										});
									}}
								>
									Chiudi tavolo
								</button>
							)}
						{apiSettings.orders.tables_open_by_filled == 1 &&
							apiSettings.mandant.fiscal_printers == 0 &&
							tableOrders.length > 0 && (
								<button
									className="button button-3"
									onClick={() => {
										setConfirm({
											title: "Sei sicuro?",
											message:
												"Verranno completati tutti gli ordini del " +
												table.name,
											onConfirm: () => fillAll(table.id),
										});
									}}
								>
									Completa tutti
								</button>
							)}
					</div>
				</>
			)}
		</div>
	);
}

function SidebarOrders({ orders }) {
	const {
		config,
		apiSettings,
		setConfirm,
		updateOrder,
		openOrder,
		setTablesListVisible,
		setSplitPopup,
		setTablePopup,
		setTableSelectorVisible,
	} = useContext(AppContext);

	const advanceFlow = (order) => {
		console.log("advanceFlow");
		const res = api.postProtected("/orders/advance_flow/" + order.id + "/", {
			flow: parseInt(order.current_flow) + 1,
		});
	};

	function group_by_flow(products) {
		const flows = {};
		for (const product of products) {
			if (!flows[product.flow]) flows[product.flow] = [];
			flows[product.flow].push(product);
		}
		return flows;
	}

	return (
		<div className="sidebar-orders">
			{orders.map((order, i) => (
				<div className="order" key={i}>
					<div className="order-header">
						<div className="order-info">
							<div>N:{order.number}</div>
							{config.mode == "local" && (
								<div>
									INT:
									{order["$loki"]}
								</div>
							)}
							<div>
								ID:
								{order.id}
							</div>
							<div>Portata:{order.current_flow}</div>
						</div>

						<div className="horizzontal-group">
							<button
								title="Preconto"
								className="button button-1 icon square"
								onClick={() =>
									updateOrder(order, {
										prebill_request: 1,
										prebill_printed: 0,
									})
								}
							>
								<PrinterIcon />
							</button>
							<button
								title="Sposta Tavolo"
								className="button button-1 icon square"
								onClick={() => setTableSelectorVisible(order)}
							>
								<MoveIcon />
							</button>
							<button
								title="Dividi ordine"
								className="button button-1 icon square"
								onClick={() => setSplitPopup({ mode: "list", order: order })}
							>
								<SplitIcon />
							</button>
							{order.current_flow < 5 && (
								<button
									title="Avanza portata"
									className="button button-1 icon square"
									onClick={() =>
										setConfirm({
											title: "Sei sicuro?",
											message:
												"Procedo con l'avanzamento a Portata " +
												(parseInt(order.current_flow) + 1),
											onConfirm: () => advanceFlow(order),
										})
									}
								>
									<AdvanceIcon />
								</button>
							)}
							{apiSettings.mandant.fiscal_printers == 0 && (
								<button
									title="Completa"
									className="button button-1 icon square"
									onClick={() =>
										setConfirm({
											title: "Evadi ordine ID " + order.number + "?",
											message:
												"Procedendo l'ordine non sarà più modificabile",
											onConfirm: async () => {
												await updateOrder(order, {
													bill: 1,
												});
											},
										})
									}
								>
									<CheckIcon />
								</button>
							)}
							<button
								title="Modifica"
								className="button button-1 icon square"
								onClick={() => {
									openOrder(order);
									setTablePopup(false);
									setTablesListVisible(false);
								}}
							>
								<EditIcon />
							</button>
						</div>
					</div>
					<div>
						{Object.keys(group_by_flow(order.products)).map((flow, i) => (
							<div key={i}>
								<div>
									<b>{"Portata " + (i + 1)}</b>
								</div>
								<div className="order-products">
									{group_by_flow(order.products)[flow].map((product, n) => (
										<div key={n}>
											<div>
												<Status status={product.status} />
												{product.qty}x {product.name}
											</div>
											<div>
												{functions.formatter.format(
													product.qty * product.price
												)}
											</div>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
					<div className="order-footer">
						<div>
							<div>Listino: {order.list_id ? order.list.name : "base"}</div>
							{order.user && (
								<div>
									<WaiterIcon />
									{order.user.name}
								</div>
							)}
							{order.name && (
								<div>
									<UserIcon />
									{order.name} {order.surname}
								</div>
							)}
						</div>
						<div>{functions.formatter.format(order.total)}</div>
					</div>
				</div>
			))}
		</div>
	);
}

function Status({ status, width = 12, height = 12 }) {
	function getColor() {
		if (status == "preparation") return "var(--status2)";
		if (status == "ready") return "var(--status3)";
		if (status == "filled") return "var(--status4)";
		return "var(--status1)";
	}
	return (
		<div
			style={{
				width: width + "px",
				height: height + "px",
				backgroundColor: getColor(),
				display: "inline-block",
				marginRight: "5px",
			}}
		></div>
	);
}

export default SidebarContent;
