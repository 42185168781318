import { useContext } from "react";
import PopUp from "./PopUp";
import { AppContext } from "../App";
import Table from "./ui/Table";
import SplitIcon from "../icons/SplitIcon";

function OrdersList({ onClose }) {
	const { config, apiSettings, openOrder, orders, updateOrder, setConfirm, setSplitPopup } =
		useContext(AppContext);

	return (
		<PopUp
			size="large"
			options={{
				onClose: onClose,
				title: "Ordini",
				content: (
					<Table
						header={true}
						config={config}
						cols={[
							{ id: "id", label: "ID", type: "id", align: "center" },
							{
								id: "delivery_type",
								label: "Tipo",
								type: "delivery_type",
								align: "center",
							},
							{
								id: "delivery_date",
								label: "Ora",
								type: "delivery_date",
								align: "center",
							},
							{
								id: "origin",
								label: "Origine",
								type: "origin",
								align: "center",
							},
							{ id: "customer", label: "Cliente", type: "customer" },
							{ id: "user", label: "Cameriere", type: "user", align: "center" },
							{ id: "info", label: "Info", type: "info" },
							{ id: "total", label: "Totale", type: "eur", align: "right" },
							{ id: "payed", label: "Pagato", type: "prepaid", align: "center" },
							{
								id: "buttons",
								label: "",
								type: "buttons",
								buttons: [
									{
										icon: <SplitIcon />,
										onClick: (order) =>
											setSplitPopup({ mode: "list", order: order }),
									},
									{
										label: "Seleziona",
										onClick: (order) => {
											openOrder(order);
											onClose();
										},
									},
									{
										label: "Completa",
										onClick: (order) => {
											setConfirm({
												title: "Sei sicuro?",
												message:
													"Procedendo l'ordine non sarà più modificabile",
												onConfirm: async () => {
													updateOrder(order, { bill: 1 });
												},
											});
										},
										visible:
											apiSettings.mandant.fiscal_printers != 1 &&
											apiSettings.pos.fast_checkout == 1,
									},
								],
							},
						]}
						rows={orders}
					/>
				),
			}}
		/>
	);
}

export default OrdersList;
